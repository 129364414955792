import { Routes, RouterModule } from "@angular/router";
import { PermissionGuard } from "app/core/service/guard/permission.guard";
import { UnderPreviewGuard } from "app/core/service/guard/under-preview.guard";
import { Roles } from "app/roles";
const {
  ADMIN,
  MANAGER,
  CREATOR,
  RIGHT_HOLDER,
  MANAGER_LIMITED,
  VIEWER,
  VIEWER_LIMITED,
} = Roles;

//Route for content layout with sidebar, navbar and footer.
export const Full_ROUTES: Routes = [
  {
    path: "dashboard",
    loadChildren: () =>
      import("app/modules/dashboard/dashboard.module").then(
        (m) => m.DashboardModule
      ),
  },
  {
    path: "admins",
    data: { permissions: [ADMIN] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/admin/admin.module").then((m) => m.AdminModule),
  },
  {
    path: "community",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/community/community.module").then((m) => m.CommunityModule),
  },
  {
    path: "playlist",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/playlist/playlist.module").then((m) => m.PlaylistModule),
  },

  {
    path: "search-settings",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/search-setting/search-setting.module").then((m) => m.SearchSettingModule),
  },

  {
    path: "settings",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/settings/settings.module").then((m) => m.SettingsModule),
  },
  {
    path: "contact-us",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/contactus/contactus.module").then((m) => m.ContactusModule),
  },
  {
    path: "subscriptions",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/subscription/subscription.module").then((m) => m.SubscriptionModule),
  },
  {
    path: "motivation-type",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/motivation-type/motivation-type.module").then((m) => m.MotivationTypeModule),
  },
  {
    path: "audios",
    data: { permissions: [ADMIN, CREATOR, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard, UnderPreviewGuard],
    loadChildren: () =>
      import("app/modules/audio/audio.module").then((m) => m.AudioModule),
  },

  {
    path: "users",
    data: { permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED] },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/users/users.module").then((m) => m.UsersModule),
  },


  {
    path: "ads-management",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/ads-management/ads-management.module").then(
        (m) => m.AdsManagementModule
      ),
  },


  {
    path: "artists",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/artist/artist.module").then((m) => m.ArtistModule),
  },
  {
    path: "notifications",
    data: {
      permissions: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    },
    canActivate: [PermissionGuard],
    loadChildren: () =>
      import("app/modules/notifications/notifications.module").then((m) => m.NotificationsModule),
  },
  {
    path: "update-profile",
    data: {
      permissions: [
        ADMIN,
        RIGHT_HOLDER,
        CREATOR,
        MANAGER,
        MANAGER_LIMITED,
        VIEWER,
        VIEWER_LIMITED,
      ],
    },
    loadChildren: () =>
      import("app/modules/profile/profile.module").then((m) => m.ProfileModule),
  },



];
