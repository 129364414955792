import { formatDate } from '@angular/common';
import { Component, HostListener, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Observable } from 'rxjs';
import { AuthService } from '../service/auth/auth.service';
import { CommonService } from '../service/common/common.service';

@Component({
  selector: 'app-google-auth',
  templateUrl: './google-auth.component.html',
  styleUrls: ['./google-auth.component.scss']
})
export class GoogleAuthComponent implements OnInit {

  code: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commonservice: CommonService,
    
    private authService: AuthService,
    public toastr: ToastrService
  ) { }


  ngOnInit(): void {
    this.router.navigate(['/dashboard']);
  }

  delay(ms: number) {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

}
