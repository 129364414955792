import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FilterService } from 'app/core/service/FilterService.service';
import { environment } from 'environments/environment';
import { BehaviorSubject } from 'rxjs';
import { finalize, map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class PollPostService extends FilterService {

  private _isLoading$ = new BehaviorSubject<boolean>(false);
  private _isLoadingpollpostSetting$ = new BehaviorSubject<boolean>(false);
  private _isLoadingpollpostSettingload$ = new BehaviorSubject<boolean>(false);
  private _isLoadingPollPost$ = new BehaviorSubject<boolean>(false);
  private _isLoadingtable$ = new BehaviorSubject<boolean>(false);
  private _isLoadingModerationSetting$ = new BehaviorSubject<boolean>(false);
  private _isLoadingModerationSettingSubmit$ = new BehaviorSubject<boolean>(false);
  private _isLoadingModerationComments$ = new BehaviorSubject<boolean>(false);
  private _isLoadingCommentDetail$ = new BehaviorSubject<boolean>(false);
  private _isLoadingModerationCommentsHistory$ = new BehaviorSubject<boolean>(false);
  private _isLoadingcalender$ = new BehaviorSubject<boolean>(false);
  private _pollpostCount$ = new BehaviorSubject<number>(0);
  private _artistCount$ = new BehaviorSubject<number>(0);
  private _creatorAccountCount$ = new BehaviorSubject<number>(0);
  private _contactUsCount$ = new BehaviorSubject<number>(0);
  private _moderationCount$ = new BehaviorSubject<number>(0);
  
  private _addPollPost$ = new BehaviorSubject<boolean>(false);

  private apiUrl = environment.apiUrl;
  constructor(private http: HttpClient) {
    super();
  }

  get isLoading$() {
    return this._isLoading$.asObservable();
  }

  get addPollPost$() {
    return this._addPollPost$.asObservable();
  }
  get isLoadingPollPost$() {
    return this._isLoadingPollPost$.asObservable();
  }
  get isLoadingModerationSetting$() {
    return this._isLoadingModerationSetting$.asObservable();
  }

  get isLoadingCommentDetail() {
    return this._isLoadingCommentDetail$.asObservable();
  }
  get isLoadingpollpostSettingload() {
    return this._isLoadingpollpostSettingload$.asObservable();
  }

  get pollpostCount$() {
    return this._pollpostCount$.asObservable();
  }
  get artistCount$() {
    return this._artistCount$.asObservable();
  }

  get moderationCount$() {
    return this._moderationCount$.asObservable();
  }
  get creatorAccountCount$() {
    return this._creatorAccountCount$.asObservable();
  }

  get contactUsCount$() {
    return this._contactUsCount$.asObservable();
  }

  get isLoadingModerationComments$() {
    return this._isLoadingModerationComments$.asObservable();
  }

  get isLoadingModerationCommentsHistory$() {
    return this._isLoadingModerationCommentsHistory$.asObservable();
  }

  get isLoadingcalender$() {
    return this._isLoadingcalender$.asObservable();
  }

  get isLoadingModerationSettingSubmit$() {
    return this._isLoadingModerationSettingSubmit$.asObservable();
  }
  get isLoadingpollpostSetting$() {
    return this._isLoadingpollpostSetting$.asObservable();
  }

  get isLoadingtable$() {
    return this._isLoadingtable$.asObservable();
  }


  GetCount(params) {
    return this.http.post(`${this.apiUrl}/post/poll/get/count`, params);
  }
  GetCountCommentModeration(params) {
    return this.http.post(`${this.apiUrl}/post/poll/comment/count`, params);
  }
  create(admin: any) {
    this._isLoading$.next(true);
    return this.http.post(`${this.apiUrl}/admin/add`, admin).pipe(
      finalize(() => {
        this._isLoading$.next(false)
      })
    );
  }

  SendMailUser(requestBody) {
    this._isLoadingPollPost$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll/user/email`, requestBody).pipe(
      finalize(() => {
        this._isLoadingPollPost$.next(false)
      })
    );
  }

  getCalenderdata(requestBody) {
    this._isLoadingcalender$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll`, requestBody).pipe(
      finalize(() => {
        this._isLoadingcalender$.next(false)
      })
    );
  }


  getdata() {
    this._isLoadingModerationSetting$.next(true);
    return this.http.get(`${this.apiUrl}/moderation/setting`).pipe(
      finalize(() => {
        this._isLoadingModerationSetting$.next(false)
      })
    );
  }

  getallComments(params) {
    this._isLoadingModerationComments$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll/comment`, params).pipe(
      finalize(() => {
        this._isLoadingModerationComments$.next(false)
      })
    );
  }

  AddPostPoll(params) {
    this._addPollPost$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll/add`, params).pipe(
      finalize(() => {
        this._addPollPost$.next(false)
      })
    );
  }

  UpdatePostPoll(params, editid) {
    this._addPollPost$.next(true);
    return this.http.put(`${this.apiUrl}/post/poll/${editid}`, params).pipe(
      finalize(() => {
        this._addPollPost$.next(false)
      })
    );
  }




  commenthistory(params) {
    this._isLoadingModerationCommentsHistory$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll/comment/history`, params).pipe(
      finalize(() => {
        this._isLoadingModerationCommentsHistory$.next(false)
      })
    );
  }



  getall(params) {
    this._isLoadingtable$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll/get/all`, params).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  getcommentpostcount() {

    return this.http
      .get(`${this.apiUrl}/post/poll/badge/count`).subscribe((response: any) => {
        this._pollpostCount$.next(response.result);
      });
  }

  speakerCount() {
    return this.http
      .get(`${this.apiUrl}/artist/mindset/manage/count`).subscribe((response: any) => {
        this._artistCount$.next(response.result);
      });
  }

  audioListCount() {
    return this.http
      .get(`${this.apiUrl}/audio/moderation/count`).subscribe((response: any) => {
        this._moderationCount$.next(response.result);
      });
  }
  getcreatorAccountRequest() {

    return this.http
      .get(`${this.apiUrl}/creator/claim/count`).subscribe((response: any) => {
        this._creatorAccountCount$.next(response.result);
      });
  }

  getcontactUsCount() {
    return this.http
      .get(`${this.apiUrl}/admin/contact_us/new/total/count`).subscribe((response: any) => {
        
        this._contactUsCount$.next(response.result);
      });
  }

  commentDetail(user) {
    this._isLoadingCommentDetail$.next(true);
    return this.http.post(`${this.apiUrl}/post/poll/comment/detail`, { userId: user.userId }).pipe(
      finalize(() => {
        this._isLoadingtable$.next(false)
      })
    );
  }

  changeStatus(requestBody) {
    return this.http.post(`${this.apiUrl}/post/poll/status/change`, requestBody);
  }

  changeStatusComment(requestBody) {
    return this.http.post(`${this.apiUrl}/post/poll/comment/status/change`, requestBody);
  }

  updatedatasetting(requestBody) {
    this._isLoadingpollpostSetting$.next(true);
    return this.http.post(`${this.apiUrl}/moderation/setting/poll/post`, requestBody).pipe(
      finalize(() => {
        this._isLoadingpollpostSetting$.next(false)
      })
    );
  }

  changeUserStatus(requestBody) {
    return this.http.post(`${this.apiUrl}/post/poll/comment/user/status/change`, requestBody);
  }

  updatedatamodetationsetting(requestBody) {
    this._isLoadingModerationSettingSubmit$.next(true);
    return this.http.post(`${this.apiUrl}/moderation/setting`, requestBody).pipe(
      finalize(() => {
        this._isLoadingModerationSettingSubmit$.next(false)
      })
    );
  }





  deletepollpost(requestBody) {

    return this.http.delete(`${this.apiUrl}/post/poll/${requestBody.poll_post_id}`);
  }

  delete(id) {
    return this.http.delete(`${this.apiUrl}/admin/${id}`, {});
    // return this.http.delete(`${this.apiUrl}/user/${id}`);
  }

  getById(id) {
    return this.http.patch(`${this.apiUrl}/post/poll/${id}`, {});
  }

  changestatus(id, status) {
    return this.http.put(`${this.apiUrl}/user/change-status/${id}`, { status });
  }

  moderationSettingpollpost() {


    this._isLoadingpollpostSettingload$.next(true);
    return this.http.get(`${this.apiUrl}/moderation/setting/poll/post`).pipe(
      finalize(() => {
        this._isLoadingpollpostSettingload$.next(false)
      })
    );

  }

}
