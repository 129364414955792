import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserModel } from 'app/core/models/user.model';
import { environment } from 'environments/environment';
import { BehaviorSubject, Observable, of, Subscription } from 'rxjs';
import { finalize, map, tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  private unsubscribe: Subscription[] = [];
  //for spinner
  isLoading$: Observable<boolean>;
  isLoadingSubject: BehaviorSubject<boolean>;
  isLoadingC$: Observable<boolean>;
  isLoadingCaptcha: BehaviorSubject<boolean>;
  //for store user detail
  currentUserSubject: BehaviorSubject<any>;
  currentUserTypeSubject: BehaviorSubject<any>;
  currentUser$: Observable<any>;
  currentUserType$: Observable<any>;
  private apiUrl = environment.apiUrl;

  get currentUserValue(): any {

    return this.currentUserSubject.value;
  }

  get currentUserValueType(): any {

    return this.currentUserTypeSubject.value;
  }

  set currentUserValue(user: any) {
    this.currentUserSubject.next(user);
  }

  set currentUserValueType(user: any) {
    this.currentUserTypeSubject.next(user);
  }




  constructor(private http: HttpClient,
    private router: Router,
    private route: ActivatedRoute) {
    this.isLoadingSubject = new BehaviorSubject<boolean>(false);
    this.isLoadingCaptcha = new BehaviorSubject<boolean>(false);
    this.isLoading$ = this.isLoadingSubject.asObservable();
    this.isLoadingC$ = this.isLoadingCaptcha.asObservable();
    this.currentUserSubject = new BehaviorSubject<any>(undefined);
    this.currentUser$ = this.currentUserSubject.asObservable();
    this.currentUserTypeSubject = new BehaviorSubject<any>(undefined);
    this.currentUserType$ = this.currentUserTypeSubject.asObservable();
  }

  socialLogin(data) {
    this.isLoadingSubject.next(true);

    return this.http.post(`${this.apiUrl}/creator/social/login`, data).pipe(
      map((auth: any) => {

        auth.result.profile_picture = `${auth.result.image ? auth.result.image : 'assets/mindset/default.jpg'}`;
        const result = this.setStorage(auth);

        this.currentUserSubject.next(auth.result);
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  login(data) {
    this.isLoadingSubject.next(true);
    return this.http.post(`${this.apiUrl}/admin/login`, data).pipe(
      map((auth: any) => {
        if(auth && auth.result){
          auth.result.profile_picture = `${auth.result.profile_picture ? auth.result.profile_picture : 'assets/mindset/default.jpg'}`;
          const result = this.setStorage(auth);
  
          this.currentUserSubject.next(auth.result);
        }
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  register(data) {
    this.isLoadingSubject.next(true);

    return this.http.post(`${this.apiUrl}/creator/register`, data).pipe(
      map((auth: any) => {

        auth.result.profile_picture = `${auth.result.profile_picture ? auth.result.profile_picture : 'assets/mindset/default.jpg'}`;
        const result = this.setStorage(auth);

        this.currentUserSubject.next(auth.result);
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  creatorClaim(formdata) {
    this.isLoadingSubject.next(true);
    return this.http.post(`${this.apiUrl}/creator/artist/claim`, formdata).pipe(
      finalize(() => this.isLoadingSubject.next(false))
    )
  }

  getCaptcha() {

    this.isLoadingCaptcha.next(true);
    return this.http.get(`${this.apiUrl}/captcha`).pipe(
      map((auth: any) => {
        return auth;
      }),
      finalize(() => this.isLoadingCaptcha.next(false))
    );
  }

  checktokenisvalid(data) {

    return this.http.post(`${this.apiUrl}/admin/verify/token`, data).pipe(
      map((auth: any) => {
        return auth;
      }),

    );
  }
  forgotpassword(data) {
    this.isLoadingSubject.next(true);
    return this.http.post(`${this.apiUrl}/admin/reset-password`, data).pipe(
      map((auth: any) => {
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }

  resetpassword(data) {
    this.isLoadingSubject.next(true);
    return this.http.post(`${this.apiUrl}/admin/change/password`, data).pipe(
      map((auth: any) => {
        return auth;
      }),
      finalize(() => this.isLoadingSubject.next(false))
    );
  }
  getUserByToken(): Observable<UserModel> {
    const token = this.getStorage();

    const currentRoute = this.route.snapshot;
    
    if (!token) {
      return of(undefined);
    } else {
      this.isLoadingSubject.next(true);
      return this.http.patch(`${this.apiUrl}/admin`, {})
        .pipe(
          map((user: any) => {
            if (user) {
              this.currentUserSubject = new BehaviorSubject<any>(user?.result);
            } else {
              this.logout();
            }
            return user;
          }),
          finalize(() => this.isLoadingSubject.next(false))
        );
    }
  }

  currentusertype() {
    const user = this.currentUserValue;

    if (user.type == 1) {
      return 'admin';
    }

    if (user.type == 2) {
      return 'creator';
    }
    if (user.type == 3) {
      return 'manager_limited';
    }

    if (user.type == 4) {
      return 'staff';
    }
    if (user.type == 5) {
      return 'viewer';
    }

    if (user.type == 6) {
      return 'viewer_limitd';
    }
    if (user.type == 8) {
      return 'right_holder';
    }
  }

  isAdminCategory() {
    const admins = [1, 3, 4, 5, 6];
    return admins.includes(this.currentUserValue?.type);
  }

  isCRUDAdmin() {
    const admins = [1, 2];
    return admins.includes(this.currentUserValue?.type);
  }

  isAdmin() {
    const admins = [1];
    return admins.includes(this.currentUserValue?.type);
  }

  isEditor() {
    const admins = [5];
    return admins.includes(this.currentUserValue?.type);
  }

  isNonCRUDAdmin() {
    const admins = [6, 7];
    return admins.includes(this.currentUserValue?.type);
  }

  isEarningAdmin() {
    const admins = [1, 2, 6];
    return admins.includes(this.currentUserValue?.type);
  }

  isNonEarningAdmin() {
    const admins = [5, 7];
    return admins.includes(this.currentUserValue?.type);
  }

  isCreator() {
    const admins = [2];
    return admins.includes(this.currentUserValue?.type);
  }

  isRH() {
    const admins = [4];
    return admins.includes(this.currentUserValue?.type);
  }

  private getStorage(): any {
    try {
      const authData = localStorage.getItem('token');
      return authData;
    } catch (error) {
      console.error(error);
      return undefined;
    }
  }

  logout() {
   
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    this.currentUserSubject.next(undefined)

    this.router.navigate(['/login'])
  }

  setStorage(auth: any) {
    if (auth?.token) {
      localStorage.setItem('token', auth.token);
      localStorage.setItem('user_id', auth.result._id);
    }
  }

  clearStorage() {
    localStorage.removeItem('token');
    localStorage.removeItem('user_id');
    this.currentUserSubject.next(undefined);
  }

}
