import { Component, ViewContainerRef, OnInit, OnDestroy, HostListener } from '@angular/core';
import { Subscription } from 'rxjs';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { filter } from 'rxjs/operators';
import { Title } from '@angular/platform-browser'; 

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html'
})
export class AppComponent implements OnInit, OnDestroy {
    subscription: Subscription;
    
   
    constructor(private router: Router,
        private activatedRoute: ActivatedRoute,
        private titleService: Title) {
    }

   
    ngOnInit() {
        this.subscription = this.router.events
            .pipe(
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                window.scrollTo(0, 0)
                const rt = this.getChild(this.activatedRoute);  
                rt.data.subscribe(data => {  
                this.titleService.setTitle(data.title +' | GoTime Motivation')});  
            });
  
    }

    getChild(activatedRoute: ActivatedRoute) {  
        if (activatedRoute.firstChild) {  
          return this.getChild(activatedRoute.firstChild);  
        } else {  
          return activatedRoute;  
        }  
    }  
      
    ngOnDestroy() {
        if (this.subscription) {
            this.subscription.unsubscribe();
        }
    }



}