import { RouteInfo } from "./vertical-menu.metadata";
import { Roles } from 'app/roles';

const { ADMIN, MANAGER, CREATOR, RIGHT_HOLDER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED } = Roles;

//Sidebar menu Routes and data
export const ROUTES: RouteInfo[] = [
  {
    path: "/dashboard",
    title: "Dashboard",
    icon: "ft-home",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, VIEWER],
  },


  {
    path: "/admins",
    title: "Admins",
    icon: "ft-user",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN],
  },
  {
    path: "",
    title: "Settings",
    icon: "ft-settings",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "/motivation-type",
        title: "Onbording Motivation Type",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "/subscriptions",
        title: "Subscription Feature",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
      },

    ],
    hasaccess: [ADMIN, MANAGER, VIEWER],
  },




  {
    path: "/audios",
    title: "Audios",
    icon: "ft-music",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED, CREATOR],
  },

  {
    path: "/playlist",
    title: "Playlists",
    icon: "ft-music",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED],
    submenu: [],
  },
  {
    path: "/artists",
    title: "Speakers & Creators",
    icon: "ft-mic",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },

  {
    path: "/users/user-list",
    title: "Users",
    icon: "ft-users",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "/contact-us/contact",
    title: "Contact Us",
    icon: "ft-mail",
    class: "",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },

  {
    path: "/",
    title: "Notifications",
    icon: "ft-bell",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [{
      path: "/notifications/notification",
      title: "Schedule Notifications",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    },
    {
      path: "/notifications/retention",
      title: "Retention Notifications",
      icon: "ft-arrow-right submenu-icon",
      class: "",
      badge: "",
      badgeClass: "",
      isExternalLink: false,
      submenu: [],
      hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
    }],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },
  {
    path: "",
    title: "Ads Management",
    icon: "fa fa-adn",
    class: "has-sub",
    badge: "",
    badgeClass: "",
    isExternalLink: false,
    submenu: [
      {
        path: "ads-management/setting",
        title: "Ads Settings",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
      {
        path: "ads-management/list",
        title: "Ads Management",
        icon: "ft-arrow-right submenu-icon",
        class: "",
        badge: "",
        badgeClass: "",
        isExternalLink: false,
        submenu: [],
        hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
      },
    ],
    hasaccess: [ADMIN, MANAGER, MANAGER_LIMITED, VIEWER, VIEWER_LIMITED,],
  },

];
